const state = {
    cart: [

    ]
}

const getters = {
    getCart: state => state.cart,
}

const actions = {
    addCartItem({ commit }, product) {
        commit('UPDATE_CART', product)
    },
    updateCartItem({ commit }, product) {
        commit('UPDATE_CART_ITEM', product)
    }
}

const mutations = {
    UPDATE_CART(state, payload) {
        if (state.cart.length === 0) {
            payload["inCart"] = true
            state.cart.push(payload)
        } else {
            const itemExists = state.cart.some(item => item.id === payload.id)
            if (!itemExists) {
                payload["inCart"] = true
                state.cart.push(payload)
            } else {
                return
            }
        }
    }
}

const cartM = {
    state,
    getters,
    mutations,
    actions
}

export default cartM