<template>
  <main class="bg-neutral-50">
    <div class="grid grid-cols-1 lg:grid-cols-2 px-4 gap-x-4 gap-y">
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-2 lg:order-1">
        <img
          class="object-center object-cover h-full w-full"
          src="https://d33d1wwnxzg4sw.cloudfront.net/433_NAHLA.jpg"
          alt=""
        />
      </div>
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-1 lg:order-2">
        <div class="h-full w-full border border-neutral-900 relative">
          <nav
            class="
              absolute
              top-0
              inset-x-0
              flex
              items-center
              justify-between
              px-4
              pt-4
            "
          >
            <div>
              <a href="">
                <span class="uppercase text-sm font-normal text-neutral-900">
                  brand
                </span>
              </a>
            </div>
            <div class="space-x-4">
              <router-link to="/products">
                <span class="uppercase text-sm font-normal text-neutral-900">
                  ready to wear
                </span>
              </router-link>
              <a href="tel:+12484707523">
                <span class="uppercase text-sm font-normal text-neutral-900">
                  contact
                </span>
              </a>
            </div>
          </nav>
          <div
            class="
              text-center
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
          >
            <div class="relative">
              <h1
                class="
                  text-6xl
                  md:text-8xl
                  font-bold
                  text-neutral-900
                  absolute
                  top-1/2
                  left-1/2
                  transform
                  -translate-y-1/2 -translate-x-1/2
                "
              >
                RUBI
              </h1>
              <img
                class="w-60 h-60 sm:h-80 sm:w-80 object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/433_NAHLA.jpg"
                alt=""
              />
            </div>
            <div class="mt-4">
              <router-link
                to="/products"
                class="
                  uppercase
                  text-base
                  font-normal
                  text-neutral-900
                  px-4
                  py-2
                  border border-neutral-900
                  rounded-lg
                  hover:bg-neutral-900 hover:text-neutral-50 hover:duration-300
                "
              >
                Shop Collection
              </router-link>
            </div>
          </div>
          <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2">
            <p class="w-48 mb-4 text-center uppercase font-thin text-sm">
              inspired by culture, movement, and fashion
            </p>
          </div>
        </div>
      </div>

      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-3">
        <div
          class="
            h-full
            w-full
            border border-neutral-900
            flex
            items-center
            relative
          "
        >
          <h2 class="ml-4 flex flex-col">
            <span>
              <span class="text-5xl md:text-8xl font-thin text-neutral-900"
                >The</span
              >
              <span
                class="
                  ml-2
                  uppercase
                  font-bold
                  text-6xl
                  md:text-9xl
                  text-neutral-900
                "
                >rubi</span
              >
            </span>
            <span
              class="
                self-end
                -mr-14
                md:-mr-24
                text-5xl
                md:text-8xl
                font-thin
                text-neutral-900
              "
              >Brand.</span
            >
          </h2>
          <div class="absolute w-80 bottom-0 right-0 mb-4 mr-4">
            <p class="text-xs font-thin text-neutral-900">
              Finding inspiration in the cultures of different countries around
              the world, we strive to represent the best of what each country
              has to offer. From Iraq, UAE and Lebanaon to France, Germany and
              Italy; RUBI has products for every woman who appreciates quality
              and are inspired by fashion.
            </p>
            <p class="mt-4 text-xs font-thin text-neutral-900">
              The RUBI luxury brand is geared toward confident women who want
              everything they own to reflect their individual style and make
              them feel beautiful.
            </p>
          </div>
        </div>
      </div>
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-4">
        <img
          class="h-full w-full object-center object-cover"
          src="https://d33d1wwnxzg4sw.cloudfront.net/182_NAHLA.jpg"
          alt=""
        />
      </div>

      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-6 lg:order-5">
        <img
          class="h-full w-full object-center object-cover"
          src="https://d33d1wwnxzg4sw.cloudfront.net/465_NAHLA.jpg"
          alt=""
        />
      </div>
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-5 lg:order-6">
        <div
          class="
            h-full
            w-full
            border border-neutral-900
            flex
            items-center
            relative
          "
        >
          <h2 class="ml-4 flex flex-col">
            <span>
              <span class="text-5xl md:text-8xl font-thin text-neutral-900"
                >The</span
              >
              <span
                class="
                  ml-2
                  uppercase
                  font-bold
                  text-6xl
                  md:text-9xl
                  text-neutral-900
                "
                >rubi</span
              >
            </span>
            <span
              class="
                self-end
                -mr-14
                md:-mr-24
                text-5xl
                md:text-8xl
                font-thin
                text-neutral-900
              "
              >Experience.</span
            >
          </h2>
          <div class="absolute top-0 left-0 mt-4 ml-4 flex">
            <p class="w-44 text-xs font-thin text-neutral-900">
              With a focus on high quality materials and bespoke tailoring, RUBI
              is a luxury brand that balances modern minimalism with exquisite
              craftsmanship.
            </p>
            <p class="ml-4 w-44 text-xs font-thin text-neutral-900">
              Each piece is made with only the softest fabrics, including silk
              charmeuse, crêpe de chine, tulle and taffeta; adorned with
              Swarovski crystals and hand cut threads; meticulously tailored by
              expert seamstresses following original designs by our world
              renowned team of designers.
            </p>
          </div>
        </div>
      </div>

      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-7">
        <div
          class="
            h-full
            w-full
            border border-neutral-900
            flex
            items-center
            relative
          "
        >
          <h2 class="ml-4 flex flex-col">
            <span>
              <span
                class="
                  uppercase
                  text-5xl
                  md:text-9xl
                  font-bold
                  text-neutral-900
                "
                >haute</span
              >
            </span>
            <span
              class="
                self-end
                -mr-14
                md:-mr-24
                text-5xl
                md:text-8xl
                font-thin
                text-neutral-900
              "
              >Couture.</span
            >
          </h2>
          <div class="absolute bottom-0 right-0 mb-4 mr-4 flex">
            <p class="w-44 text-xs font-thin text-neutral-900">
              Discover a new standard of luxury with our unique and select
              designs. These hand-crafted pieces are engineered in the finest
              fabrics, with attention to detail and design.
            </p>
            <p class="ml-4 w-44 text-xs font-thin text-neutral-900">
              RUBI, Couture is designed and developed by hand in a small
              atelier, where only the finest materials are used. Designed with
              the customer in mind, it's always a dream to wear RUBI. Each piece
              is an exclusive piece of art, perfected by our skilled craftsmen
              and ateliers designers. A luxury item that must be cherished as a
              jewel of your fine wardrobe.
            </p>
          </div>
        </div>
      </div>
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-8">
        <img
          class="h-full w-full object-center object-cover"
          src="https://d33d1wwnxzg4sw.cloudfront.net/271_NAHLA.jpg"
          alt=""
        />
      </div>

      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-10 lg:order-9">
        <div class="h-full w-full overflow-y-auto">
          <div class="grid grid-cols-1">
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/789_NAHLA.jpg"
              alt=""
            />
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/794_NAHLA.jpg"
              alt=""
            />
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/797_NAHLA.jpg"
              alt=""
            />
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/739_NAHLA.jpg"
              alt=""
            />
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/748_NAHLA.jpg"
              alt=""
            />
            <img
              class="object-center object-cover"
              src="https://d33d1wwnxzg4sw.cloudfront.net/757_NAHLA.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-9 lg:order-10">
        <div
          class="
            h-full
            w-full
            border border-neutral-900
            flex
            items-center
            relative
          "
        >
          <h2
            class="
              px-4
              w-full
              text-8xl
              font-thin
              text-neutral-900
              flex flex-col
            "
          >
            <span>
              <span class="text-6xl md:text-9xl font-bold text-neutral-900"
                >RUBI</span
              >
            </span>
            <span class="uppercase text-5xl md:text-8xl text-center"
              >RTW '23</span
            >
            <span class="self-start text-5xl md:text-8xl"> Campaign. </span>
          </h2>
          <div class="absolute w-80 top-0 right-0 mt-4 mr-4">
            <p class="text-xs font-thin text-neutral-900">
              RUBI designs are inspired by couture, yet created with a modern
              twist and reinvented for the everyday woman. Each design tells a
              unique story that is accessible to women all over the world, each
              with their own tastes, style and personality.
            </p>
            <p class="mt-4 text-xs font-thin text-neutral-900">
              RUBI Ready To Wear Campaign '23 collection combines elegant savoir
              faire and edgy street wear to create a refined, versatile, and
              elevated look. We believe that fashion should empower women and
              people of all genders to express their individuality, as well as
              celebrate their identity.
            </p>
          </div>
          <router-link
            to="/products"
            class="
              absolute
              bottom-0
              left-0
              mb-4
              ml-4
              uppercase
              text-base
              font-normal
              text-neutral-900
              px-4
              py-2
              border border-neutral-900
              rounded-lg
              hover:bg-neutral-900 hover:text-neutral-50 hover:duration-300
            "
          >
            Shop Collection
          </router-link>
        </div>
      </div>

      <div class="h-screen pt-4 pb-4 bg-neutral-50 order-11">
        <div class="h-full w-full border border-neutral-900 relative">
          <h2
            class="
              uppercase
              absolute
              top-0
              left-0
              mt-2
              lg:mt-0
              ml-4
              text-6xl
              md:text-9xl
              font-bold
              text-neutral-900
              z-10
            "
          >
            rubi
          </h2>
          <a
            href="https://maps.apple.com/?address=375%20Hamilton%20Row,%20Birmingham,%20MI%20%2048009,%20United%20States&auid=1505095001523991724&ll=42.547716,-83.213263&lsp=9902&q=Luna-tic&_ext=CjMKBQgEEOIBCgQIBRADCgUIBhDfAQoECAoQAAoECFIQBAoECFUQAAoECFkQBgoFCKQBEAESJim81L5giEVFQDEvks7+Cc5UwDk6quS8rkZFQEHB5Wo3Qs1UwFAD"
            class="
              inline-flex
              cursor-pointer
              z-10
              absolute
              bottom-0
              inset-x-0
              items-center
              justify-between
              mx-4
              mb-4
            "
          >
            <div class="flex items-center">
              <p class="text-3xl md:text-6xl font-bold text-neutral-900">42°</p>
              <p
                class="
                  uppercase
                  ml-1
                  text-4xl
                  md:text-6xl
                  font-thin
                  text-neutral-900
                "
              >
                w
              </p>
            </div>
            <p
              class="
                text-center
                w-48
                uppercase
                text-xs
                font-light
                text-neutral-900
              "
            >
              inspired by culture, movement, and fashion
            </p>
            <div class="flex items-center">
              <p class="text-3xl md:text-6xl font-bold text-neutral-900">
                -52°
              </p>
              <p
                class="
                  uppercase
                  ml-1
                  text-4xl
                  md:text-6xl
                  font-thin
                  text-neutral-900
                "
              >
                n
              </p>
            </div>
          </a>
          <div class="absolute top-1/2 left-0 transform -translate-y-1/2">
            <a
              href="tel:+12484707523"
              class="
                cursor-pointer
                inline-flex
                items-center
                z-10
                ml-4
                uppercase
                text-lg
                font-normal
                text-neutral-900
                px-4
                py-1
                border border-neutral-900
                rounded-lg
                hover:bg-neutral-900 hover:text-neutral-50 hover:duration-300
              "
            >
              atelier reservation
            </a>
            <p class="ml-4 w-80 mt-2 font-thin text-neutral-900">
              Click above to call and schedule an appointment for RUBI bespoke
              services
            </p>
          </div>
          <img
            class="
              hidden
              h-full
              w-full
              absolute
              top-0
              right-0
              object-center object-cover
            "
            src="https://d33d1wwnxzg4sw.cloudfront.net/660_bwo.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="hidden pt-4 pb-4 lg:block h-screen bg-neutral-50 order-12">
        <img
          class="h-full w-full object-center object-cover"
          src="https://d33d1wwnxzg4sw.cloudfront.net/660_bwo.jpg"
          alt=""
        />
      </div>
    </div>
    <!-- grid -->
  </main>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
