<template>
  <div>
    <main class="bg-neutral-50 relative">
      <div
        v-if="allProducts.length > 0"
        class="grid grid-cols-1 lg:grid-cols-2 px-4 space-x-4"
      >
        <div
          v-for="product in allProducts"
          :key="product.id"
          class="h-screen py-4 bg-neutral-50"
        >
          <div
            class="
              relative
              h-full
              w-full
              border border-neutral-900
              overflow-hidden
            "
          >
            <img
              class="absolute h-full right-0 object-cover"
              :src="product.images[0]"
              alt=""
            />
            <div class="absolute top-0 left-0 mt-4 ml-4">
              <h2 class="flex flex-col text-5xl font-thin uppercase">
                <span>{{ product.name.split(" ")[0] }}</span>
                <span class="font-bold text-neutral-900">
                  {{ product.name.split(" ")[1] }}
                </span>
                <span>{{ product.name.split(" ")[2] }}</span>
              </h2>
            </div>
            <router-link class="absolute mb-2 mr-4 bottom-0 right-0" to="/">
              <span class="uppercase text-6xl font-bold text-neutral-900"
                >rubi</span
              >
            </router-link>
            <p
              class="absolute w-64 bottom-0 left-0 mb-4 ml-4 text-sm font-thin"
            >
              {{ product.description }}
            </p>

            <div v-if="product.metadata.quantity > 0">
              <button
                v-if="!product.inCart"
                @click.prevent="addCartItem(product)"
                class="
                  absolute
                  px-4
                  py-2
                  top-1/2
                  left-0
                  transform
                  -translate-y-1/2
                  ml-4
                  bg-transparent
                  uppercase
                  text-neutral-900
                  border border-neutral-900
                  hover:bg-neutral-900 hover:text-neutral-50
                  duration-300
                "
              >
                add to cart
              </button>

              <button
                v-else
                @click="checkout"
                class="
                  absolute
                  px-4
                  py-2
                  top-1/2
                  left-0
                  transform
                  -translate-y-1/2
                  ml-4
                  bg-neutral-900
                  uppercase
                  text-neutral-50
                  border border-neutral-900
                  hover:bg-neutral-50 hover:text-neutral-900
                  duration-300
                "
              >
                checkout
              </button>
            </div>
            <div v-else>
              <button
                disabled
                v-if="!product.inCart"
                @click.prevent="addCartItem(product)"
                class="
                  absolute
                  px-4
                  py-2
                  top-1/2
                  left-0
                  transform
                  -translate-y-1/2
                  ml-4
                  bg-transparent
                  uppercase
                  text-neutral-50
                  bg-neutral-900
                  border border-neutral-900
                "
              >
                SOLD OUT
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grid grid-cols-1 lg:grid-cols-2 lg:space-x-4 px-4">
        <div class="h-screen pt-4 pb-4 bg-neutral-50">
          <div class="h-full w-full relative border border-neutral-900">
            <h1
              class="
                absolute
                top-0
                left-0
                mt-2
                ml-4
                flex flex-col
                text-9xl
                font-bold
                text-neutral-900
              "
            >
              RUBI
              <span class="text-8xl font-thin ml-32">Upcoming</span>
              <span class="text-8xl font-thin">Launches</span>
            </h1>
            <div
              class="
                mt-8
                absolute
                top-1/2
                left-0
                transform
                -translate-y-1/2
                space-y-4
              "
            >
              <a
                href="mailto:manager@lunadesignstudio.com"
                class="
                  px-4
                  py-2
                  ml-4
                  rounded-md
                  uppercase
                  font-light
                  border border-neutral-900
                  hover:bg-neutral-900 hover:text-neutral-50
                  duration-300
                "
              >
                email atelier
              </a>
              <a
                href="tel:123-456-7890"
                class="
                  block
                  px-4
                  py-1
                  ml-4
                  rounded-md
                  uppercase
                  font-light
                  border border-neutral-900
                  hover:bg-neutral-900 hover:text-neutral-50
                  duration-300
                "
                >call atelier</a
              >
            </div>
            <div class="absolute bottom-0 right-0 mb-2 mr-4">
              <p
                class="
                  w-96
                  text-sm
                  font-thin
                  text-neutral-900
                  flex flex-col
                  mb-2
                  mr-4
                "
              >
                <span>
                  We apologize for the inconvenience caused by the recent
                  sellout of our clothing line. Rest assured, we are working
                  hard to restock and bring you even more stylish and luxurious
                  items. Our designers have been busy creating a new collection
                  that promises to exceed your expectations.
                </span>
                <span class="mt-4">
                  We can’t wait for you to see the new pieces and we promise
                  they will be worth the wait. In the meantime, please stay
                  tuned for updates and we thank you for your patience and
                  continued support.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="h-screen pt-4 pb-4 bg-neutral-50">
          <div class="h-full w-full overflow-y-auto">
            <div class="grid grid-cols-1">
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/789_NAHLA.jpg"
                alt=""
              />
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/794_NAHLA.jpg"
                alt=""
              />
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/797_NAHLA.jpg"
                alt=""
              />
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/739_NAHLA.jpg"
                alt=""
              />
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/748_NAHLA.jpg"
                alt=""
              />
              <img
                class="object-center object-cover"
                src="https://d33d1wwnxzg4sw.cloudfront.net/757_NAHLA.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "ProductView",
  methods: {
    ...mapActions(["getProducts", "addCartItem"]),
    async checkout() {
      var lineItems = [];
      this.getCart.forEach((product) => {
        var priceId = product.default_price.id;
        var defaultQuantity = 1;

        var lineItem = {
          price: priceId,
          quantity: defaultQuantity,
        };

        lineItems.push(lineItem);
        return lineItems;
      });

      const checkoutSession = await axios.post(
        "/.netlify/functions/stripeCheckout",
        lineItems
      );
      window.location.href = checkoutSession.data;
    },
  },
  mounted() {
    this.getProducts();
  },
  computed: {
    ...mapGetters(["allProducts", "getCart"]),
  },
};
</script>