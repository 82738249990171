<template>
  <div class="h-screen w-screen relative bg-neutral-50">
    <iframe
    class="absolute top-0 left-0 h-full w-full"
      src="https://d33d1wwnxzg4sw.cloudfront.net/RUBI_intro.mp4"
      allow="autoplay; fullscreen"
    ></iframe>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>