import { createStore } from 'vuex'
import productsModule from './modules/product'
import cartM from './modules/cart/index'

export default createStore({
  modules: {
    productsModule,
    cartM
  }
})
