import axios from "axios";

const state = {
    products: []
}

const getters = {
    allProducts: (state) => state.products
}

const actions = {
    async getProducts({ commit }) {
        const stripeProducts = await axios.get("/.netlify/functions/getProducts")
        console.log(stripeProducts.data)
        const products = stripeProducts.data.filter(product => product.active === true)
        console.log('********** filtered products')
        console.log(products)
        commit('setProducts', products)
    }
}

const mutations = {
    setProducts: (state, products) => (state.products = products)
}

const productsModule = {
    state,
    getters,
    actions,
    mutations
}

export default productsModule